<template>
  <div>
    <!-- 查询设置区 -->
    <l-search :is-collapsed="true">
      <l-form-model layout="inline" laba-width="70px">
        <l-form-model-item label="手机">
          <l-input v-model="form.phone" placeholder="请输入手机号" :max-length="11" />
        </l-form-model-item>
        <l-form-model-item label="邮箱">
          <l-input v-model="form.email" placeholder="请输入邮箱号" />
        </l-form-model-item>
        <l-form-model-item label="城市">
          <l-input v-model="form.city" placeholder="请输入城市名" />
        </l-form-model-item>
        <l-form-model-item label="国家">
          <l-input v-model="form.country" placeholder="请输入国家" />
        </l-form-model-item>
        <l-form-model-item label="来源">
          <l-select v-model="form.type" placeholder="请选择">
            <l-select-option value="wx">
              魔方创拼室小程序
            </l-select-option>
            <l-select-option value="h5">
              H5
            </l-select-option>
          </l-select>
        </l-form-model-item>
        <l-form-model-item label="性别">
          <l-select v-model="form.gender" placeholder="请选择">
            <l-select-option value="1">
              男
            </l-select-option>
            <l-select-option value="2">
              女
            </l-select-option>
          </l-select>
        </l-form-model-item>
      </l-form-model>
      <template slot="right">
        <div>
          <l-button type="primary" @click="search(1)">查询</l-button>
          <l-button class="c-user-manage_buttom-reset" @click="reset">重置</l-button>
          <l-button type="primary" @click="report">导出</l-button>
        </div>
      </template>
    </l-search>
    <!-- 查询设置区 结束-->
    <!-- 列表展示区 -->
    <l-table-common
      class="c-user-manage_table"
      :data-source="tableData"
      @change="search"
    >
      <l-table-column
        data-index="index"
        title="序号"
      />
      <l-table-column
        data-index="name"
        title="昵称"
      />
      <l-table-column
        title="性别"
      >
        <template slot-scope="scope">
          {{ scope.gender | gender }}
        </template>
      </l-table-column>
      <l-table-column
        data-index="phone"
        title="手机号"
      />
      <l-table-column
        data-index="email"
        title="邮箱"
      />
      <l-table-column
        data-index="city"
        title="城市"
      />
      <l-table-column
        data-index="country"
        title="国家"
      />
      <l-table-column
        title="最后登录时间"
      >
        <template slot-scope="scope">
          <div>
            {{ scope.laststLoginTime | date }}
          </div>
        </template>
      </l-table-column>
      <l-table-column
        data-index="userType"
        title="用户类型"
      />
      <l-table-column
        title="用户来源"
      >
        <template slot-scope="scope">
          <div>
            {{ scope.type == 'wx' ? '魔方创拼室小程序' : 'H5' }}
          </div>
        </template>
      </l-table-column>
    </l-table-common>
    <!-- 列表展示区结束 -->
  </div>
</template>

<script>
import { debounce } from '@/utils/utils'// 引入防抖函数
export default {
  data() {
    return {
      form: {
        name: '',
        email: '',
        country: '',
        city: '',
        gender: undefined,
        phone: '',
        type: undefined,
      },
      ids: [], // 存储表格复选框选中数据的id
      tableData: {
        isLoading: false,
        total: 0,
        page: 1,
        size: 8,
        list: [],
      },
      selectedRowKeys: [],
      elements: {},
      sessionKey: sessionStorage.getItem('sessionKey'),
    }
  },
  mounted() {
    this.search(1)
  },
  methods: {
    async search(page) {
      page = (page && page.current) || page || this.tableData.page || 1
      this.tableData.isLoading = true
      // 校验手机号
      if (!(/^1\d{10}$/.test(this.form.phone)) && this.form.phone !== '') {
        this.$message.error('手机号格式不正确')
        this.tableData.isLoading = false
        return
      }
      // 校验邮箱
      if (!(/^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+(.[a-zA-Z0-9_-])+/.test(this.form.email)) && this.form.email !== '') {
        this.$message.error('邮箱格式不正确')
        this.tableData.isLoading = false
        return
      }
      try {
        const data = await this.$store.dispatch('userManage/userManageList', {
          city: this.form.city,
          country: this.form.country,
          email: this.form.email,
          gender: this.form.gender,
          phone: this.form.phone,
          type: this.form.type,
          page: page,
          size: this.tableData.size
        })
        this.tableData.isLoading = false
        this.tableData.list = data.list || []
        this.tableData.list.forEach((item, index) => {
          item.index = index + 1
        })
        this.tableData.total = data.count
        this.tableData.page = page
      } catch (e) {
        this.tableData = this.$options.data().tableData
      }
    },
    reset() {
      this.form = this.$options.data().form
      debounce(this.search(1), 500)// 使用防抖，防止数据刷新过快页面抖动
    },
    // 导出
    async report() {
      this.form.url = '/api/admin/instance/ganUser/export'
      this.$store.dispatch('userManage/userManagedownloadtable', this.form)
    },
  },
}

</script>

<style lang='scss'>
// 点击按钮的样式设置
.c-search .ant-input {
  width: 160px;
}
.c-search .ant-select {
  width: 160px;
}
.c-search .c-search-right {
  padding: 3px 0 0 0;
}
/* 重置按钮 */
.c-user-manage_buttom-reset {
  margin: 0 10px;
}

/* 展示列表设置 */
.c-user-manage_table {
  margin-top: 20px;
}
</style>
